import React, { useEffect, useState } from "react";
import { Button, Divider, Grid, IconButton, InputAdornment, Link, Paper, Switch} from "@mui/material";
import { getTenantLocalStorage } from "../../../service/tenant/TenantService";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PrincipalLayout from "../menu/PrincipalLayout";
import { calculateDiscount, calculateGrandTotal, calculateSubTotal, calculateTaxes, calculateTotal, createWorkOrder, getAllReminderTypes, getWorkOrderById, updateWorkOrder } from "../../../service/tenant/WorkOrderService";
import ErrorMessageChip from "../util/ErrorMessageChip";
import constants from "../util/Constants";
import { useLocation, useNavigate } from "react-router";
import InputComponent from "../util/InputComponent";
import { getAllActiveTeams, getUserData, isInRole, security_constants } from "../../../service/tenant/SecurityService";
import SuccessMessageChip from "../util/SuccessMessageChip";
import { createEmptyWorkOrder, createEmptyLineItem, parseToFloatAmount, validateWorkOrder, createEmptyWorkOrderItem, parseToFloat } from "../../../service/tenant/ValidationService";
import { getAllActiveClients, getShortenClientById, getTaxRateByClient } from "../../../service/tenant/ClientService";
import AutocompleteComponent from "../util/AutocompleteComponent";
import { getAllActiveProjectsByClientId } from "../../../service/tenant/ProjectService";
import { getAllActiveItems } from "../../../service/tenant/ItemService";
import { Delete, Done, DoneAll, EditNote, LocationOn, PlayArrow } from "@mui/icons-material";
import QuoteItemDetails from "../quotes/QuoteItemDetails";
import DateComponent from "../util/DateComponent";
import SelectComponent from "../util/SelectComponent";
import { getLineItemStatusIcon, getWorkOrderStatusName, isLineItemApprovable, isLineItemEditable, isLineItemOnGoing, isWorkOrderCompleted, isWorkOrderEditable, isWorkOrderNew, isWorkOrderPendingApproveDate, status } from "../../../service/tenant/StatusService";
import { searchJobsByAnyAttribute } from "../../../service/tenant/JobService";
import SwitchComponent from "../util/SwitchComponent";
import UploaderComponent from "../util/UploaderComponent";
import ConfirmDialog from "../util/ConfirmDialog";
import { sendBroadcastRoleAdministratorWorkOrderOngoingNotification, sendBroadcastRoleStaffWorkOrderOngoingNotification, sendUserWorkOrderSentNotification } from "../../../service/tenant/NotificationsService";
import { useStompClient } from "react-stomp-hooks";
import { discountTypesCatalog } from "../../../service/tenant/QuoteService";
import DiscountDialog from "../util/DiscountDialog";
import { buildClientAddressLinkToMaps } from "../../../service/tenant/ServiceHelper";



function EditWorkOrder( props ){
    
    const location = useLocation();
    const tenant = getTenantLocalStorage();
    const userData = getUserData();
    const userId = userData.id;
    var workOrderId = location.state.workOrderId;
    const tenantId = tenant.id;
    const navigate = useNavigate();

    const [workOrder, setWorkOrder] = useState(null);
    const [clientAddress, setClientAddress] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [activeClients, setActiveClients] = useState([]);
    const [activeProjects, setActiveProjects] = useState([]);
    const [activeJobs, setActiveJobs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [activeItems, setActiveItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
    const [reminderTypes, setReminderTypes] = useState([]);
    const [activeTeams, setActiveTeams] = useState([]);
    const [activeTeamUsers, setactiveTeamUsers] = useState([]);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openConfirmLineItemDialog, setOpenConfirmLineItemDialog] = useState(false);
    const [lineItemIndex, setLineItemIndex] = useState(null);
    const [lineItemDialogTitle, setLineItemDialogTitle] = useState('');
    const [lineItemDialogMessage, setLineItemDialogMessage] = useState('');
    const [isFromJob, setIsFromJob] = useState(false);
    const [openDiscountDialog, setOpenDiscountDialog] = useState(false);
    const [isInAdminRole, setIsInAdminRole] = useState(false);


    const [saveEvent, setSaveEvent] = useState(false);

    const stompClient = useStompClient();
    

    useEffect(() => {
        
        console.debug('[EditWorkOrder] workOrderId: ', workOrderId);

        setIsLoading(true);

        if( workOrderId != null && workOrderId != '' ){
            getWorkOrderById(workOrderId, tenantId).then( workOrder => {

                if( workOrder != null ){
                    console.debug('[EditWorkOrder][useEffect] workOrder: ', workOrder);
                    setWorkOrder(workOrder);
                }
                setIsLoading(false);
            });
        } else {

            const newWorkOrder = createEmptyWorkOrder(userData.uuid);
            var newLineItems = location.state.lineItems;
            const client = location.state.client;
            const job = location.state.job;
            const project = location.state.project;
            const quote = location.state.quote;

            
            if( newLineItems !== null && job === null){
                newWorkOrder.work_order_item_list = [];

                newLineItems.forEach( lineItem => {
                    let workOrderItemAux = createEmptyWorkOrderItem();
                    workOrderItemAux.line_item = lineItem;
                    workOrderItemAux.line_item.status = status.lineItemStatus.pendingApproval.status;
                    newWorkOrder.work_order_item_list.push(workOrderItemAux);
                });

                newWorkOrder.discount = quote.discount;
                newWorkOrder.discount_type = quote.discount_type;
                newWorkOrder.include_taxes = quote.include_taxes;

            } else if( newLineItems !== null && job !== null ){
                newWorkOrder.job = job;
                setIsFromJob(true);
                newWorkOrder.work_order_item_list = [];

                newLineItems.forEach( lineItem => {
                    let workOrderItemAux = createEmptyWorkOrderItem();
                    if( lineItem.status === status.lineItemStatus.pendingApproval.status ){
                        workOrderItemAux.line_item = lineItem;
                        newWorkOrder.work_order_item_list.push(workOrderItemAux);
                    }
                    
                });

            }

            if( client ){
                newWorkOrder.client = client;
            }

            if( project ){
                newWorkOrder.project = project;
            }

            console.debug('[EditWorkOrder][useEffect] newWorkOrder: ', newWorkOrder);
            setWorkOrder(newWorkOrder);
            setIsLoading(false);
        }

        setIsLoading(true);

        getAllReminderTypes().then( allReminderTypes =>{
            if( allReminderTypes !== null ){
                setReminderTypes(allReminderTypes);
            }
        });


        getAllActiveClients( tenantId ).then( activeClientsResponse => {
            if( activeClientsResponse != null ){
                setActiveClients(activeClientsResponse);
            }
        });

        getAllActiveItems( tenantId ).then( activeItemsResponse => {
            if( activeItemsResponse ){
                setActiveItems(activeItemsResponse);
            }

            
        })


        getAllActiveTeams( tenantId ).then( activeTeamsResponse => {
            if( activeTeamsResponse != null ){
                setActiveTeams(activeTeamsResponse);
            }

            setIsLoading(false);
        });

        const validateAdminRole = isInRole(security_constants.roleCodes.administrator);
        setIsInAdminRole(validateAdminRole);
        console.debug('[EditWorkOrder] validateAdminRole: ', validateAdminRole);


    }, [location.state.workOrderId]);
    

    useEffect( () => {
        if( workOrder && workOrder.client ){

            console.log('[EditWorkOrders][useEffect] workOrder.client: ', workOrder.client);
            const clientId = workOrder.client.id;

            setIsLoading(true);
            getAllActiveProjectsByClientId(clientId, tenantId).then( activeProjectsResponse => {
                if( activeProjectsResponse != null ){
                    setActiveProjects(activeProjectsResponse);
                }
                setIsLoading(false);
            });

            getShortenClientById(clientId, tenantId).then( clientResponse => {
                if( clientResponse != null ){
                    setClientAddress(clientResponse.client_address);
                }
            });

            setIsLoading(true);
            searchJobsByAnyAttribute('', clientId, '', tenantId).then( activeJobsResponse => {
                if( activeJobsResponse != null ){
                    setActiveJobs(activeJobsResponse);
                }
                setIsLoading(false);
            });

        }
    }, [workOrder ? workOrder.client: {}]);


    useEffect( () => {
        
        if( workOrder && workOrder.team ){

            console.log('[EditWorkOrder][useEffect] workOrder.team: ', workOrder.team);
            
            setactiveTeamUsers( workOrder.team.user_list );

        }
    }, [workOrder ? workOrder.team: {}]);

    useEffect( () => {
        if( workOrder ){

            console.log('[EditWorkOrder][useEffect] saveEvent: ');
            
            handleClickSave();

        }
    }, [saveEvent]);


    const handleClickSave = () => {
            
        setSuccessMessage('');
        setErrorMessage('');

        let isValidWorkOrder = validateWorkOrder(workOrder);
        isValidWorkOrder = isValidWorkOrder && ( workOrder.client != null );

        console.debug('[EditWorkOrder][handleClickSave] workOrder: ', workOrder);
        console.debug('[EditWorkOrder][handleClickSave] workOrderId: ', workOrderId);

        if( isValidWorkOrder && workOrder.id != null && workOrder.id != ''  ){
            handleUpdateWorkOrder();
        } else if( isValidWorkOrder && ( workOrder.id == null || workOrder.id == '' ) ){
            handleCreateWorkOrder();
        } else {
            setErrorMessage(constants.errors.validationErrorRequiredFields);
        }
    }

    const handleClickSaveAndSend = () => {
        
        let workOrderAux = {...workOrder};
        workOrderAux.status = status.workOrderStatus.pendingApproveDates.status;
        setWorkOrder(workOrderAux);

        sendUserWorkOrderSentNotification(userId, workOrderAux, tenantId, stompClient)

        setSaveEvent(!saveEvent);
    }


    const handleUpdateWorkOrder = () => {
        const tenantId = tenant.id;

        setIsLoading(true);
        updateWorkOrder(workOrder, tenantId).then( workOrderResponse => {

            if( workOrderResponse != null ){
                console.debug('[EditWorkOrder][handleUpdateWorkOrder] workOrderResponse: ', workOrderResponse);
                setWorkOrder(workOrderResponse);
                setSuccessMessage(constants.errors.editWorkOrderSuccess);
            } else {
                setErrorMessage(constants.errors.editWorkOrderError)
            }

            setIsLoading(false);
        });

    }

    const handleCreateWorkOrder = () => {
        const tenantId = tenant.id;

        setIsLoading(true);
        createWorkOrder(workOrder, tenantId).then( workOrderResponse => {

            if( workOrderResponse != null ){
                console.debug('[EditWorkOrder][handleCreateWorkOrder] workOrderResponse: ', workOrderResponse);
                setWorkOrder(workOrderResponse);
                workOrderId = workOrderResponse.id;
                console.debug('[EditWorkOrder][handleCreateWorkOrder] workOrderId: ', workOrderId);
                setSuccessMessage(constants.errors.createWorkOrderSuccess);
            } else {
                setErrorMessage(constants.errors.createWorkOrderError)
            }

            setIsLoading(false);
        });

    }


    const handleChangeWorkOrderAttribute = (attributeName, attributeValue) => {
        if( workOrder ){
            let workOrderAux = { ...workOrder };
            workOrderAux[attributeName] = attributeValue;

            if( attributeName === 'client' ){
                workOrderAux.project = null; 
                workOrderAux.job = null;
                setActiveProjects([]);
                setActiveJobs([]);
            }

            setWorkOrder(workOrderAux);
        }
    }

    const handleChangeWorkOrderDiscountAttribute = (attributeName, attributeValue) => {
        if( workOrder ){
            let workOrderAux = { ...workOrder };

            const discount = parseToFloat(attributeValue);
            workOrderAux[attributeName] = discount;
            console.debug('[EditQuote][handleChangeWorkOrderDiscountAttribute] workOrderAux: ', workOrderAux);
            setWorkOrder(workOrderAux);
        }
    }


    const handleChangeLineItemAttribute = (index, attributeName, attributeValue) => {
        if( workOrder && workOrder.work_order_item_list ){
            let workOrderAux = { ...workOrder };
            console.debug('[EditWorkOrder][handleChangeLineItemAttribute] attributeName: ', attributeName);

            let workOrderItemListAux = workOrderAux[attributeName];

            if( !workOrderItemListAux[index].line_item ){
                workOrderItemListAux[index].line_item = createEmptyLineItem();
            }
            

            workOrderItemListAux[index].line_item.item = attributeValue;
            workOrderItemListAux[index].line_item.unit_price = getLineItemPrice(attributeValue);
            workOrderItemListAux[index].line_item.details = attributeValue.description;
            workOrderItemListAux[index].line_item.name = attributeValue.name;
            console.debug('[EditWorkOrder][handleChangeLineItemAttribute] workOrderItemListAux: ', workOrderItemListAux);

            
            setWorkOrder(workOrderAux);
        }
    }


    

    const getLineItemPrice = ( item ) => {
        if(  item && item.price_unit_list && item.price_unit_list.length > 0 ){
            return parseToFloatAmount(item.price_unit_list[0].price);
        }
        return 0;
    }

    const handleChangeLineItemQuantity = (index, attributeName, attributeValue) => {
        if( workOrder && workOrder.work_order_item_list ){
            let workOrderAux = { ...workOrder };

            let workOrderItemListAux = workOrderAux[attributeName];

            workOrderItemListAux[index].line_item.quantity = attributeValue;
            setWorkOrder(workOrderAux);
        }
    }

    const handleChangeLineItemUnitPrice = (index, attributeName, attributeValue) => {
        if( workOrder && workOrder.work_order_item_list ){
            let workOrderAux = { ...workOrder };

            let workOrderItemListAux = workOrderAux[attributeName];
            const unitPrice = parseToFloatAmount(attributeValue);
            workOrderItemListAux[index].line_item.unit_price = unitPrice;
            setWorkOrder(workOrderAux);
        }
    }

    const handleAddLineItem = () => {
        const lineItem = createEmptyLineItem();
        const workOrderItem = createEmptyWorkOrderItem();
        workOrderItem['line_item'] = lineItem;
        let workOrderAux = { ...workOrder };
        workOrderAux.work_order_item_list.push(workOrderItem);
        setWorkOrder(workOrderAux);
    }

    const handleDeleteLineItem = (index) => {
        let workOrderAux = { ...workOrder };
        workOrderAux.work_order_item_list.splice(index, 1);
        workOrderAux = validateWorkOrderCompleted(workOrderAux);
        setWorkOrder(workOrderAux);
        setSaveEvent(!saveEvent);
    }


    const buildCompleteClientAddress = () => {
        if( clientAddress ){
            return clientAddress.number + ' ' + clientAddress.street + ', ' + clientAddress.city + ', ' + clientAddress.locality;
        }
        return '';
    }


    const handleShowItemDetails = (item) => {
        console.debug('[EditWorkOrder][handleShowItemDetails] item: ', item);
        setSelectedItem(item);
        setOpenDetailsDialog(true);
    }

    const handleCloseDetailsDialog = () => {
        setSelectedItem(null);
        setOpenDetailsDialog(false);
    }

    const handleConfirmApproveItem = (index) => {
        console.debug('[EditWorkOrder][handleApproveItem] index: ', index);
        if( workOrder && workOrder.work_order_item_list ){
            let workOrderAux = { ...workOrder };
            let workOrderItemListAux = workOrderAux.work_order_item_list;
            if( workOrderAux.status == status.workOrderStatus.pendingApproveDates.status ){
                workOrderItemListAux[index].line_item.status = status.lineItemStatus.pending.status;
            } else if ( workOrderAux.status == status.workOrderStatus.onGoing.status &&
                workOrderItemListAux[index].line_item.status == status.lineItemStatus.pendingApproval.status
             ){
                workOrderItemListAux[index].line_item.status = status.lineItemStatus.onGoing.status;
            } else if( workOrderAux.status == status.workOrderStatus.onGoing.status &&
                workOrderItemListAux[index].line_item.status == status.lineItemStatus.onGoing.status ){
                workOrderItemListAux[index].line_item.status = status.lineItemStatus.completed.status;
            }

            workOrderAux = validateWorkOrderCompleted(workOrderAux);
            
            setWorkOrder(workOrderAux);
            setSaveEvent(!saveEvent);
        }
    }

    const validateWorkOrderCompleted = (workOrderAux) => {
        if( workOrderAux.status == status.workOrderStatus.onGoing.status ){
            var allLineItemsCompleted = true;
            let workOrderItemListAux = workOrderAux.work_order_item_list;
            workOrderItemListAux.forEach( workOrderItem =>{
                if( workOrderItem.line_item.status != status.lineItemStatus.completed.status){
                    allLineItemsCompleted = false;
                }
            });

            if( allLineItemsCompleted ){
                console.debug('[EditWorkOrder][validateWorkOrderCompleted] all line items are completed! ');
                workOrderAux.status = status.workOrderStatus.completed.status;
            }
        }

        return workOrderAux;
    } 

    const handleApproveItem = (index) =>{
        if( workOrder && workOrder.work_order_item_list ){
            const lineItem = workOrder.work_order_item_list[index].line_item;
            const subTotal = calculateSubTotal(workOrder.work_order_item_list[index]);
            
            setLineItemIndex(index);
            setLineItemDialogTitle('Do you confirm to approve this line item?');
            setLineItemDialogMessage('Line item: ' + lineItem.name + ', price: ' + subTotal);
            setOpenConfirmLineItemDialog(true);
        }
        
    }

    const handleCompleteItem = (index) =>{
        if( workOrder && workOrder.work_order_item_list ){
            const lineItem = workOrder.work_order_item_list[index].line_item;
            const subTotal = calculateSubTotal(workOrder.work_order_item_list[index]);
            
            setLineItemIndex(index);
            setLineItemDialogTitle('Do you confirm this line item is completed?');
            setLineItemDialogMessage('Line item: ' + lineItem.name + ', price: ' + subTotal);
            setOpenConfirmLineItemDialog(true);
        }
        
    }

    const handleConfirmApproveDatesDialog = () => {
        console.debug('[EditWorkOrder][handleApproveDates] ...');
        if( workOrder ){
            let workOrderAux = { ...workOrder };
            workOrderAux.status = status.workOrderStatus.onGoing.status;

            if( workOrderAux.work_order_item_list ) {
                let workOrderItemListAux = workOrderAux.work_order_item_list;
                workOrderItemListAux.forEach((workOrderItem) => {
                    if( workOrderItem.line_item.status == status.lineItemStatus.pending.status ){
                        workOrderItem.line_item.status = status.lineItemStatus.onGoing.status;
                    }
                });
            }
            setWorkOrder(workOrderAux);

            sendBroadcastRoleAdministratorWorkOrderOngoingNotification(userId, workOrderAux, tenantId, stompClient);
            sendBroadcastRoleStaffWorkOrderOngoingNotification(userId, workOrderAux, tenantId, stompClient);

            setSaveEvent(!saveEvent);
        }
    }

    const handleApproveDates = () => {
        setOpenConfirmDialog(true);
    }

    const handleCreateInvoice = () => {

        var lineItems = [];

        workOrder.work_order_item_list.forEach( workOrderItem => {
            let invoiceItemAux = {...workOrderItem.line_item};
            invoiceItemAux.id = null;
            invoiceItemAux.status = null;
            lineItems.push(invoiceItemAux);
        });

        console.debug('[EditWorkOrder][handleCreateInvoice] lineItems: ', lineItems);

        navigate(constants.routes.createInvoice, {
            state: {
                invoiceId: '',
                lineItems: lineItems,
                client: workOrder.client,
                workOrder: workOrder,
            }
        });

    }

    return (
        
        <PrincipalLayout section={constants.menu.admin_workorders_section} isLoading={isLoading}
                        notificationNumber={props.notificationNumber} 
                        setNotificationNumber={props.setNotificationNumber}
        >

                <Toolbar />

                <Paper sx={constants.styles.paperContainer}>

                    <Grid container direction="row" spacing={0}
                        justifyContent={'left'}
                    >
                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                            <Typography 
                                variant="h6"
                                sx={constants.styles.textTitle}
                            >
                                Work order: {workOrder && workOrder.id ? ' #' + workOrder.id + ' - ' : ''} {workOrder ? workOrder.title : ''} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Typography>
                            
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <ErrorMessageChip message={errorMessage} />
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <SuccessMessageChip message={successMessage} setMessage={setSuccessMessage} />
                        </Grid>
                        
                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={6}>

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >
                                
                                <InputComponent 
                                    fieldName={"Title"} 
                                    fieldValue={ workOrder ? workOrder.title: '' } 
                                    fieldId={'input-workOrder-title'} 
                                    attributeName={'title'} 
                                    handleChangeField={handleChangeWorkOrderAttribute}
                                    required={true} 
                                    disabled={isWorkOrderCompleted(workOrder ? workOrder.status : false) }
                                />

                                <AutocompleteComponent
                                    fieldId={'input-workOrder-client'}
                                    fieldName={'Client'} 
                                    fieldValue={workOrder ? workOrder.client : null}
                                    attributeName={'client'}
                                    optionList={activeClients}
                                    isClient={true}
                                    handleChangeField={handleChangeWorkOrderAttribute}
                                    disabled={!isWorkOrderEditable(workOrder ? workOrder.status : false) }
                                />

                                <AutocompleteComponent
                                    fieldId={'input-workOrder-project'}
                                    fieldName={'Project'} 
                                    fieldValue={workOrder ? workOrder.project : null}
                                    attributeName={'project'}
                                    optionList={activeProjects}
                                    optionAttributeLabel={'name'}
                                    isClient={false}
                                    handleChangeField={handleChangeWorkOrderAttribute}
                                    disabled={!isWorkOrderEditable(workOrder ? workOrder.status : false) }
                                />

                                <AutocompleteComponent
                                    fieldId={'input-workOrder-job'}
                                    fieldName={'Job'} 
                                    fieldValue={workOrder ? workOrder.job : null}
                                    attributeName={'job'}
                                    optionList={activeJobs}
                                    optionAttributeLabel={'title'}
                                    isClient={false}
                                    handleChangeField={handleChangeWorkOrderAttribute}
                                    disabled={true}
                                />


                               
                            </Grid>
                        
                        </Grid>

                        
                        <Grid item 
                            sx={constants.styles.sectionTitle} xs={12} md={6} 
                        >

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >

                                <InputComponent 
                                    fieldName={"Status"} 
                                    fieldValue={ workOrder ? getWorkOrderStatusName( workOrder.status ): '' } 
                                    fieldId={'input-workOrder-status'} 
                                    attributeName={'status'} 
                                    required={false}
                                    disabled={true} 
                                />


                                <InputComponent 
                                    fieldName={"Phone"} 
                                    fieldValue={ workOrder && workOrder.client && workOrder.client.user ? workOrder.client.user.phone : '' } 
                                    fieldId={'input-workOrder-client-user-phone'} 
                                    attributeName={'phone'} 
                                    handleChangeField={handleChangeWorkOrderAttribute}
                                    required={false} 
                                    disabled={true}

                                />

                                <InputComponent 
                                    fieldName={"Email"} 
                                    fieldValue={ workOrder && workOrder.client && workOrder.client.user ? workOrder.client.user.email : '' } 
                                    fieldId={'input-workOrder-client-user-email'} 
                                    attributeName={'phone'} 
                                    handleChangeField={handleChangeWorkOrderAttribute}
                                    required={false} 
                                    disabled={true}

                                />
                                

                                <InputComponent 
                                    fieldName={"Address"} 
                                    fieldIcon={
                                        <IconButton 
                                            sx={{ p: 0 }} 
                                            title="Open map"
                                            href={buildClientAddressLinkToMaps(clientAddress)}
                                            target="_blank"
                                        >
                                            <LocationOn sx={constants.styles.iconSize} color="error"/>
                                        </IconButton>
                                    }
                                    fieldValue={ workOrder && workOrder.client ? buildCompleteClientAddress() : '' } 
                                    fieldId={'input-workOrder-client-address'} 
                                    attributeName={'address'} 
                                    handleChangeField={handleChangeWorkOrderAttribute}
                                    required={false} 
                                    disabled={true}
                                    multiline={true}
                                    rows={2}

                                />


                            </Grid>

                        </Grid>
                        

                        

                        <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                            <Divider/>
                        </Grid>

                        <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                            <Typography sx={constants.styles.textNormal} >
                                        &nbsp;
                            </Typography>
                        </Grid>

                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                        
                            <Grid container direction="row" spacing={0}
                                    justifyContent={'left'}
                                    alignContent={'center'}
                                    alignItems={'center'}
                                >

                            {  workOrder && workOrder.work_order_item_list && 
                                <>
                                    <Grid item sx={constants.styles.sectionInput} xs={4} md={4}>
                                        <Typography sx={constants.styles.textNormal} >
                                            Product/Service
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={1} md={1}>
                                        <Typography sx={constants.styles.textNormal} >
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Qty.
                                        </Typography>
                                    </Grid>
                                    
                                    { isInAdminRole ?
                                        <>
                                        <Grid item sx={constants.styles.sectionInput} xs={2} md={2}>
                                            <Typography sx={constants.styles.textNormal} >
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unit
                                            </Typography>
                                        </Grid>
                                        <Grid item sx={constants.styles.sectionInput} xs={2} md={2}>
                                            <Typography sx={constants.styles.textNormal} >
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total
                                            </Typography>
                                        </Grid>
                                        <Grid item sx={constants.styles.sectionInput} xs={1} md={1}>
                                            <Typography sx={constants.styles.textNormal} >
                                                &nbsp;
                                            </Typography>
                                        </Grid>
                                        </>
                                        :
                                        <Grid item sx={constants.styles.sectionInput} xs={5} md={5}>
                                            <Typography sx={constants.styles.textNormal} >
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </Typography>
                                        </Grid>
                                    }
                                </>
                            }
                            {  workOrder && workOrder.work_order_item_list &&

                                workOrder.work_order_item_list.map( (workOrderItem, index) => (
                                    <>
                                    
                                    

                                    <AutocompleteComponent
                                        fieldId={'input-work_order_item_list-'+index}
                                        fieldName={'Item'} 
                                        fieldValue={workOrderItem.line_item.item}
                                        fieldIndex={index}
                                        attributeName={'work_order_item_list'}
                                        optionList={activeItems}
                                        optionAttributeLabel={'name'}
                                        hideLabel={true}
                                        handleChangeField={handleChangeLineItemAttribute}
                                        sx={ constants.styles.textFieldLineItem }
                                        inputSx={4}
                                        inputMd={4}

                                        disabled={!isLineItemEditable(workOrderItem.line_item.status)}
                                    />
                                    
                                    
                                    { getLineItemStatusIcon(workOrderItem.line_item.status) }
                                    
                                    
                                    <InputComponent 
                                        fieldName={'Quantity'} 
                                        fieldValue={workOrderItem.line_item.quantity} 
                                        fieldIndex={index}
                                        fieldId={'input-workOrder_item_qty-'+index} 
                                        attributeName={'work_order_item_list'} 
                                        handleChangeField={handleChangeLineItemQuantity}
                                        required={true} 
                                        hideLabel={true}
                                        sx={ constants.styles.textFieldSmall }
                                        inputSx={1}
                                        inputMd={1}
                                        InputProps={{
                                            inputProps: {
                                                style: { textAlign: "right" },
                                                min: 0,
                                            }
                                        }}
                                        numberValidation={true}
                                        validationErrorMessage={''}

                                        disabled={!isLineItemEditable(workOrderItem.line_item.status)}
                                    />

                                    { isInAdminRole ?
                                        <>
                                            <InputComponent 
                                                fieldName={'Unit price'} 
                                                fieldValue={workOrderItem.line_item.unit_price} 
                                                fieldIndex={index}
                                                fieldId={'input-workOrder_item_price-'+index} 
                                                attributeName={'work_order_item_list'} 
                                                handleChangeField={handleChangeLineItemUnitPrice}
                                                required={true} 
                                                hideLabel={true}
                                                sx={ constants.styles.textFieldMedium }
                                                inputSx={2}
                                                inputMd={2}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    inputProps: {
                                                        style: { textAlign: "right" },
                                                        min: 0,
                                                    }
                                                }}
                                                numberValidation={true}
                                                validationErrorMessage={''}

                                                disabled={!isLineItemEditable(workOrderItem.line_item.status)}
                                            />

                                            <InputComponent 
                                                fieldName={'Total'} 
                                                fieldValue={workOrderItem.line_item.unit_price ? calculateSubTotal(workOrderItem) : 0} 
                                                fieldIndex={index}
                                                fieldId={'input-workOrder_item_total-'+index} 
                                                attributeName={'work_order_item_list'} 
                                                handleChangeField={handleChangeLineItemUnitPrice}
                                                required={false} 
                                                hideLabel={true}
                                                disabled={true}
                                                sx={ constants.styles.textFieldMedium }
                                                inputSx={2}
                                                inputMd={2}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    inputProps: {
                                                        style: { textAlign: "right" },
                                                    }
                                                }}
                                            />
                                        </>
                                        :
                                       <></>
                                    }

                                    <Grid item sx={constants.styles.sectionInput} xs={1} md={1}>
                                        <IconButton 
                                            sx={{ p: 0 }} 
                                            title="Edit item"
                                            onClick={()=> handleShowItemDetails(workOrderItem.line_item)}
                                        >
                                            <EditNote sx={constants.styles.iconSize}/>
                                        </IconButton>
                                        { isLineItemEditable(workOrderItem.line_item.status) && isInAdminRole &&
                                        <>
                                            <IconButton 
                                                sx={{ p: 0 }} 
                                                title="Delete item"
                                                onClick={() => handleDeleteLineItem(index) }
                                            >
                                                <Delete sx={constants.styles.iconSize}/>
                                            </IconButton>
                                        </>
                                        }
                                        { isLineItemApprovable(workOrderItem.line_item.status) && isInAdminRole &&
                                        <>
                                            <IconButton 
                                                sx={{ p: 0 }} 
                                                title="Approve item"
                                                onClick={() => handleApproveItem(index) }
                                            >
                                                <Done sx={constants.styles.iconSize} color="success"/>
                                            </IconButton>
                                        </>
                                        }
                                        { isLineItemOnGoing(workOrderItem.line_item.status) && isInAdminRole &&
                                        <>
                                            <IconButton 
                                                sx={{ p: 0 }} 
                                                title="Complete item"
                                                onClick={() => handleCompleteItem(index) }
                                            >
                                                <DoneAll sx={constants.styles.iconSize} color="success"/>
                                            </IconButton>
                                        </>
                                        }
                                    </Grid>

                                    { isInAdminRole ?
                                        <></>
                                        :
                                        <Grid item sx={constants.styles.sectionInput} xs={4} md={5}>
                                            <Typography sx={constants.styles.textNormal} >
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </Typography>
                                        </Grid>
                                    }   

                                    </>
                                ))

                            }

                            {  workOrder && workOrder.work_order_item_list && isInAdminRole &&
                                <>
                                    <Grid item sx={constants.styles.sectionInput} xs={4} md={4}>
                                        <Typography sx={constants.styles.textNormal} >
                                            &nbsp;
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={1} md={1}>
                                        <Typography sx={constants.styles.textNormal} >
                                            &nbsp;
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={5} md={4}>
                                        <Grid container direction="row" spacing={0}
                                            justifyContent={'left'}
                                            alignContent={'center'}
                                            alignItems={'center'}
                                        >
                                            <Grid item sx={constants.styles.sectionInput} xs={7} md={8}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    Subtotal:
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={constants.styles.sectionInput} xs={3} md={3}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    ${ calculateTotal(workOrder) }
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={constants.styles.sectionInput} xs={7} md={8}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    <Link
                                                        component="button"
                                                        onClick={() => setOpenDiscountDialog(true)}
                                                        disabled={!isWorkOrderEditable(workOrder ? workOrder.status : '')}
                                                    >
                                                        Discount:
                                                    </Link>
                                                </Typography>
                                                {workOrder && workOrder.discount_type && workOrder.discount_type === discountTypesCatalog.percentageDiscount.id &&
                                                    (
                                                        <Typography sx={constants.styles.textSmall} align="right">
                                                            ({workOrder ? workOrder.discount : 0.0}%)
                                                        </Typography>
                                                    )
                                                }
                                            </Grid>
                                            <Grid item sx={constants.styles.sectionInput} xs={3} md={3}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    ${ calculateDiscount(workOrder) }
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={constants.styles.sectionInput} xs={7} md={8}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    <Switch 
                                                        id={ 'input-quote-include_taxes' }
                                                        checked={ workOrder ? workOrder.include_taxes : false }
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        size="small"
                                                        onChange={ (e) => handleChangeWorkOrderAttribute('include_taxes', e.target.checked) }
                                                        disabled={!isWorkOrderEditable(workOrder ? workOrder.status : '')}
                                                    />
                                                    Taxes:
                                                </Typography>
                                                
                                            </Grid>
                                            <Grid item sx={constants.styles.sectionInput} xs={3} md={3}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    ${ calculateTaxes(workOrder) }
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={constants.styles.sectionInput} xs={7} md={8}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    <strong> Total:  </strong>
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={constants.styles.sectionInput} xs={3} md={3}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    <strong>${ calculateGrandTotal(workOrder) }</strong>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={1} md={1}>
                                        <Typography sx={constants.styles.textNormal} >
                                            &nbsp;
                                        </Typography>
                                    </Grid>
                                </>
                            } 

                            </Grid>

                            
                        </Grid>

                        { !isWorkOrderCompleted(workOrder ? workOrder.status : false) && !isFromJob && isInAdminRole && (
                            <Grid item  
                                    xs={12} md={12} 
                                    sx={constants.styles.sectionButtons}
                                >
                                <Button 
                                    variant="outlined" 
                                    size="small" 
                                    sx={constants.styles.buttonNormal}
                                    onClick={handleAddLineItem}
                                    > 
                                        Add item 
                                </Button>
                            </Grid>
                        )}

                        <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                            <Divider/>
                        </Grid>

                        <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                            <Typography sx={constants.styles.textNormal} >
                                        <strong> Work assignment </strong>
                            </Typography>
                        </Grid>

                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={6}>

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >


                                <DateComponent
                                    fieldName={"Start date"} 
                                    fieldValue={ workOrder ? workOrder.start_date: '' } 
                                    fieldId={'input-request-start_date'} 
                                    attributeName={'start_date'} 
                                    handleChangeField={handleChangeWorkOrderAttribute}
                                    required={false} 
                                    disabled={!isWorkOrderEditable(workOrder ? workOrder.status : false)}
                                />

                                <DateComponent
                                    fieldName={"End date"} 
                                    fieldValue={ workOrder ? workOrder.end_date: '' } 
                                    fieldId={'input-request-end_date'} 
                                    attributeName={'end_date'} 
                                    handleChangeField={handleChangeWorkOrderAttribute}
                                    required={false} 
                                    disabled={!isWorkOrderEditable(workOrder ? workOrder.status : false)}
                                />

                                <SwitchComponent 
                                    fieldName={"Reminders for client"} 
                                    fieldValue={ workOrder ? workOrder.reminders_client : false } 
                                    fieldId={'input-request-reminders_client'} 
                                    attributeName={'reminders_client'} 
                                    handleChangeField={handleChangeWorkOrderAttribute} 
                                    disabled={isWorkOrderCompleted(workOrder ? workOrder.status : false)}
                                />

                                {  workOrder && workOrder.reminders_client &&
                                    <>
                                    
                                        <SelectComponent
                                            fieldName={"Type"} 
                                            fieldValue={ workOrder ? workOrder.reminder_type: '' } 
                                            fieldId={'input-workOrder-reminder_type'} 
                                            attributeName={'reminder_type'} 
                                            optionList={reminderTypes}
                                            optionAttributeName={'name'}
                                            handleChangeField={handleChangeWorkOrderAttribute}
                                            disabled={isWorkOrderCompleted(workOrder ? workOrder.status : false)}
                                        />

                                        <InputComponent 
                                            fieldName={"Reminder"} 
                                            fieldValue={ workOrder  ? workOrder.reminder : '' } 
                                            fieldId={'input-workOrder-reminder'} 
                                            attributeName={'reminder'} 
                                            handleChangeField={handleChangeWorkOrderAttribute}
                                            required={false} 
                                            multiline={true}
                                            rows={4}
                                            disabled={isWorkOrderCompleted(workOrder ? workOrder.status : false)}
                                        />
                                    
                                    </>
                                }
                                
                                <UploaderComponent
                                        object={workOrder}
                                        prefix={'work_order'}
                                        folder={'work_orders'}
                                        imageListAttributeName={'work_order_staff_image_list'}
                                        setObject={setWorkOrder}
                                        saveObject={handleClickSave}
                                        title={'Attached files for staff'}
                                />

                            </Grid>
                        </Grid>


                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={6}>

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >
                                
                                    <AutocompleteComponent
                                        fieldId={'input-request-team'}
                                        fieldName={'Team'} 
                                        fieldValue={workOrder ? workOrder.team : null}
                                        attributeName={'team'}
                                        optionList={activeTeams}
                                        optionAttributeLabel={'name'}
                                        isClient={false}
                                        handleChangeField={handleChangeWorkOrderAttribute}
                                        disabled={isWorkOrderCompleted(workOrder ? workOrder.status : false)}
                                    />

                                    <AutocompleteComponent
                                        fieldId={'input-request-user'}
                                        fieldName={'Employee'} 
                                        fieldValue={workOrder ? workOrder.user : null}
                                        attributeName={'user'}
                                        optionList={activeTeamUsers}
                                        optionAttributeLabel={'first_name'}
                                        isUser={true}
                                        handleChangeField={handleChangeWorkOrderAttribute}
                                        disabled={isWorkOrderCompleted(workOrder ? workOrder.status : false)}
                                    />

                                    <UploaderComponent
                                        object={workOrder}
                                        prefix={'work_order'}
                                        folder={'work_orders'}
                                        imageListAttributeName={'work_order_image_list'}
                                        setObject={setWorkOrder}
                                        saveObject={handleClickSave}
                                    />


                            </Grid>
                        </Grid>
                        

                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={6}>

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >
                                
                                <InputComponent 
                                    fieldName={"Notes from client"} 
                                    fieldValue={ workOrder ? workOrder.client_notes: '' } 
                                    fieldId={'input-job-client_notes'} 
                                    attributeName={'client_notes'} 
                                    handleChangeField={handleChangeWorkOrderAttribute}
                                    required={false} 
                                    multiline={true}
                                    rows={4}
                                    disabled={true}

                                    labelSx={12}
                                    labelMd={12}

                                    inputSx={12}
                                    inputMd={12}

                                    fullWidth={true}
                                />

                                <InputComponent 
                                    fieldName={"Notes from staff"} 
                                    fieldValue={ workOrder ? workOrder.staff_notes: '' } 
                                    fieldId={'input-job-staff_notes'} 
                                    attributeName={'staff_notes'} 
                                    handleChangeField={handleChangeWorkOrderAttribute}
                                    required={false} 
                                    multiline={true}
                                    rows={4}

                                    labelSx={12}
                                    labelMd={12}

                                    inputSx={12}
                                    inputMd={12}

                                    fullWidth={true}
                                    disabled={isWorkOrderCompleted(workOrder ? workOrder.status : false)}
                                />


                            </Grid>
                        </Grid>
                        


                        <Grid 
                            container
                            alignItems={'flex-end'}
                            alignContent={'flex-end'}
                            flexDirection={'column'}
                        >

                            <Grid item  
                                xs={12} md={12} 
                                sx={constants.styles.sectionButtons}
                            >
                                { workOrder && isWorkOrderPendingApproveDate( workOrder.status ) && isInAdminRole &&
                                    <>
                                        <Button 
                                            variant="outlined" 
                                            size="small" 
                                            sx={constants.styles.buttonNormal}
                                            onClick={handleApproveDates}
                                        > 
                                        Approve dates
                                        </Button>
                                    </>
                                }
                                { workOrder && isWorkOrderEditable( workOrder.status ) && workOrder.id && isInAdminRole &&
                                    <>
                                        <Button 
                                            variant="outlined" 
                                            size="small" 
                                            sx={constants.styles.buttonNormal}
                                            onClick={handleClickSaveAndSend}
                                        > 
                                        Save and send to client
                                        </Button>
                                    </>
                                }
                                { workOrder && isWorkOrderCompleted( workOrder.status ) && isInAdminRole &&
                                    <>
                                        <Button 
                                            variant="outlined" 
                                            size="small" 
                                            sx={constants.styles.buttonNormal}
                                            onClick={handleCreateInvoice}
                                        > 
                                        Create invoice
                                        </Button>
                                    </>
                                }
                                <Button 
                                    variant="outlined" 
                                    size="small" 
                                    sx={constants.styles.buttonNormal}
                                    onClick={handleClickSave}
                                    > 
                                        Save 
                                </Button>
                            </Grid>

                        </Grid>
                        
                    </Grid>
                    
                </Paper>
                
                <QuoteItemDetails
                    openDetailsDialog={openDetailsDialog}
                    handleCloseDetailsDialog={handleCloseDetailsDialog}
                    item={selectedItem}
                />

                <ConfirmDialog 
                    key={'confirm_approve_dates'}
                    openConfirmDialog={openConfirmDialog}
                    setOpenConfirmDialog={setOpenConfirmDialog}
                    handleConfirmAction={handleConfirmApproveDatesDialog}
                    title={'Do you approve this dates?'}
                    message={workOrder ? 'Work order schedule, start date: ' + workOrder.start_date + ' end date: ' + workOrder.end_date : ''}
                />

                <ConfirmDialog 
                    key={'confirm_approve_line_item'}
                    openConfirmDialog={openConfirmLineItemDialog}
                    setOpenConfirmDialog={setOpenConfirmLineItemDialog}
                    handleConfirmAction={handleConfirmApproveItem}
                    title={lineItemDialogTitle}
                    message={lineItemDialogMessage}
                    itemObject={lineItemIndex}
                />


                <DiscountDialog 
                    key={'discount_work_order_dialog'}
                    openDiscountDialog={openDiscountDialog}
                    setOpenDiscountDialog={setOpenDiscountDialog}
                    title={'Work order discount'}
                    
                    fieldDiscountTypeName={'Discount'}
                    fieldDiscountTypeValue={ workOrder ? workOrder.discount_type : null }
                    fieldDiscountTypeId={'input-work_order-discount_type'}
                    fieldDiscountTypeAttributeName={'discount_type'}
                    handleChangeDiscountTypeField={handleChangeWorkOrderAttribute}
                    
                    fieldDiscountName={'Discount'}
                    fieldDiscountValue={ workOrder ? workOrder.discount : null }
                    fieldDiscountId={'input-work_order-discount'}
                    discountAttributeName={'discount'}
                    discountRequired={true}

                    handleChangeDiscountField={handleChangeWorkOrderDiscountAttribute}

                />

        </PrincipalLayout>

        
    );
}

export default EditWorkOrder;
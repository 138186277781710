//Invoice Service Delegate
import axios from "axios";
import constants from "../../components/jobs-manager/util/Constants";
import { getDataRespose } from "./ServiceHelper";
import { getUserData } from "./SecurityService";
import {status} from "./StatusService";
import { parseToFloatAmount } from "./ValidationService";
import { getTaxRateByClient } from "./ClientService";
import { discountTypesCatalog } from "./QuoteService";

export const depositTypes = {
    deposit: 1,
    payment: 2,
}

export const searchInvoicesByAnyAttribute = async(anyInfo, clientId, projectId, jobId, workOrderId,  tenanId) => {
    var response = await axios.get( constants.api.invoiceApiUrl + '/' + tenanId + '/shorten/filter_any', { 
        params: {
            any_attribute: anyInfo,
            client_id: clientId,
            project_id: projectId,
            job_id: jobId,
            work_order_id: workOrderId,
        },
        headers: constants.api.defaultHeaders 
    } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const searchInvoicesByAnyAttributeAndStatus = async(anyInfo, clientId, projectId, jobId, workOrderId, status,  tenanId) => {
    var response = await axios.get( constants.api.invoiceApiUrl + '/' + tenanId + '/shorten/filter_any', { 
        params: {
            any_attribute: anyInfo,
            client_id: clientId,
            project_id: projectId,
            job_id: jobId,
            work_order_id: workOrderId,
            status: status,
        },
        headers: constants.api.defaultHeaders 
    } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const searchInvoicesByAnyAttributeForClient = async(anyInfo, clientId, projectId, jobId, workOrderId,  tenanId) => {
    var response = await axios.get( constants.api.invoiceApiUrl + '/' + tenanId + '/shorten/client_filter_any', { 
        params: {
            any_attribute: anyInfo,
            client_id: clientId,
            project_id: projectId,
            job_id: jobId,
            work_order_id: workOrderId,
        },
        headers: constants.api.defaultHeaders 
    } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const getInvoiceById = async(invoiceId, tenanId) => {

    var response = await axios.get( constants.api.invoiceApiUrl + '/' + tenanId + '/' + invoiceId, {
        headers: constants.api.defaultHeaders 
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}


export const updateInvoice = async(invoice, tenanId) => {

    const user = getUserData();
    invoice.user_id = user.uuid;

    console.debug('[InvoiceService][updateInvoice] invoice: ', invoice);

    var response = await axios.put( constants.api.invoiceApiUrl + '/' + tenanId + '/', invoice, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const logicalDeleteInvoice = async(invoice, tenanId) => {

    const user = getUserData();
    invoice.user_id = user.uuid;
    invoice.status = status.invoiceStatus.cancelled.status;

    console.debug('[InvoiceService][logicalDeleteInvoice] invoice: ', invoice);

    var response = await axios.delete( constants.api.invoiceApiUrl + '/' + tenanId + '/logical_delete/' + invoice.id , {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}


export const createInvoice = async(invoice, tenanId) => {

    const user = getUserData();
    invoice.user_id = user.uuid;

    console.debug('[InvoiceService][createInvoice] invoice: ', invoice);

    var response = await axios.post( constants.api.invoiceApiUrl + '/' + tenanId + '/', invoice, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const sendInvoiceEmailPost = async(pdfFile, invoiceId, tenanId) => {

    console.debug('[InvoiceService][sendInvoiceEmail] invoiceId: ', invoiceId);

    const formData = new FormData();
    formData.append('pdfFile', pdfFile);

    var response = await axios.post( constants.api.invoiceApiUrl + '/' + tenanId + '/email/' + invoiceId, formData, {
        headers: constants.api.defaultMultipartHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const calculateSubTotalInvoice = ( invoiceItem ) => {
    if( invoiceItem && invoiceItem.unit_price && invoiceItem.quantity ){
        const subTotal = invoiceItem.unit_price * invoiceItem.quantity;
        return parseToFloatAmount(subTotal);
    }
    return 0;
}


export const calculatTotalInvoice = (invoice) => {
    let total = 0;
    if( invoice && invoice.invoice_item_list ){
        invoice.invoice_item_list.forEach( invoiceItem => {
            const subTotal = parseFloat(calculateSubTotalInvoice(invoiceItem));
            total = total + subTotal;
        });
    }
    return parseToFloatAmount(total);
}


export const calculatDiscountInvoice = (invoice) => {
    let totalDiscount = 0;
    const total = parseFloat(calculatTotalInvoice(invoice));
    if( invoice && invoice.discount ){
        if( discountTypesCatalog.percentageDiscount.id === invoice.discount_type ){
            totalDiscount = total * (invoice.discount/100);
        } else {
            totalDiscount = invoice.discount;
        }
    }
    return parseToFloatAmount(totalDiscount);
}


export const calculatTaxesInvoice = (invoice) => {
    const total = parseFloat(calculatTotalInvoice(invoice));
    const taxRate = getTaxRateByClient();
    let taxes = 0.0;
    if( invoice && invoice.include_taxes === true ){
        taxes = total * taxRate;
    }
    return parseToFloatAmount(taxes);
}

export const calculatGrandTotalInvoice = (invoice) => {
    const taxRate = getTaxRateByClient();
    const total = parseFloat(calculatTotalInvoice(invoice));
    let taxes = 0.0;
    if( invoice && invoice.include_taxes === true ){
        taxes = total * taxRate;
    }

    const totalDiscount = calculatDiscountInvoice(invoice);
    const grandTotal = total + taxes - totalDiscount;

    

    return parseToFloatAmount(grandTotal);
}



export const calculatInvoiceBalance = (invoice) => {
    const grandTotal = calculatGrandTotalInvoice(invoice);
    console.debug('[InvoiceService][calculatInvoiceBalance] grandTotal: ', grandTotal);
    let depositsAmount = 0;
    invoice.invoice_deposit_list.forEach( invoiceDeposit => {
        console.debug('[InvoiceService][calculatInvoiceBalance] invoiceDeposit.status: ', invoiceDeposit.status);
        console.debug('[InvoiceService][calculatInvoiceBalance] invoiceDeposit.amount: ', invoiceDeposit.amount);
        if( invoiceDeposit.status === status.invoiceDeposit.approved.status ){
            depositsAmount = depositsAmount + invoiceDeposit.amount;
        }
    });
    console.debug('[InvoiceService][calculatInvoiceBalance] depositsAmount: ', depositsAmount);
    return parseToFloatAmount( grandTotal - depositsAmount);
}


export const setPaymentMethods = (paymentMethods) => {
    console.debug('[InvoiceService][setPaymentMethods] paymentMethods: ', paymentMethods);
    if( paymentMethods != null  ){
        localStorage.setItem(constants.storage.paymentMethods, JSON.stringify(paymentMethods));
    }
}

export const getPaymentMethods = () => {
    const paymentMethodsStr = localStorage.getItem(constants.storage.paymentMethods);
    if( paymentMethodsStr != null ){
        const paymentMethods = JSON.parse(paymentMethodsStr);
        console.debug('[WorkOrderService][getPaymentMethods] paymentMethods: ', paymentMethods);
        return paymentMethods;
    }
    return null;
}


export const getAllPaymentMethods = async () => {
    const paymentMethods = getPaymentMethods();

    if( paymentMethods != null ){
        return paymentMethods;
    } else {
        var response = await axios.get( constants.api.invoiceApiUrl + '/payment_methods', {
            headers: constants.api.defaultHeaders 
        } )
        .catch( error => {
            console.error('[ERROR] error: ', error);
        });
        const paymentMethodsArray = getDataRespose(response);
        setPaymentMethods(paymentMethodsArray);
        return paymentMethodsArray;
    }

}




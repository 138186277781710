import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import constants from "../util/Constants";
import ErrorMessageChip from "../util/ErrorMessageChip";
import AutocompleteComponent from "../util/AutocompleteComponent";
import { getCategoriesFirstLevel } from "../../../service/tenant/CategoryService";
import { createEmptyPriceUnit, validateItem } from "../../../service/tenant/ValidationService";
import { createItem, getPublicItemUrl, updateItem } from "../../../service/tenant/ItemService";
import { getTenantLocalStorage } from "../../../service/tenant/TenantService";
import InputComponent from "../util/InputComponent";
import UploaderComponent from "../util/UploaderComponent";
import { QRCodeSVG } from "qrcode.react";


function EditItem( props ){

    const [errorMessageDialog, setErrorMessageDialog] = useState('');
    const [activeCategories, setActiveCategories] = useState([]);

    const tenant = getTenantLocalStorage();
    const tenantId = tenant.id;

    useEffect( () => {

        props.setIsLoading(true);
        getCategoriesFirstLevel( tenantId ).then( activeCategoriesResponse => {
            if( activeCategoriesResponse !== null ){
                setActiveCategories(activeCategoriesResponse);
            }
            props.setIsLoading(false);
        });

    }, []);



    const handleSaveItemNoClose = () => {
        handleSaveItemDefault(false);
    }

    const handleSaveItem = () => {
        handleSaveItemDefault(true);
    }

    const handleSaveItemDefault = (closeDialog) => {
        
        console.debug('[EditItem][handleSaveItem] item: ', props.item);

        setErrorMessageDialog('');
        props.setSuccessMessage('');
        props.setErrorMessage('');

        let isValidItem = validateItem(props.item);

        console.debug('[EditItem][handleSaveItem] isValidItem: ', isValidItem);

        if( !props.item.price_unit_list || props.item.price_unit_list.length === 0 || props.item.price_unit_list[0] === null || 
            !props.item.price_unit_list[0].price || props.item.price_unit_list[0].price === null || 
            !props.item.category_list || props.item.category_list.length === 0 || props.item.category_list[0] === null
        ){
            setErrorMessageDialog(constants.errors.itemValidationErrorNoCategory);
        } else if( isValidItem && props.item.id && props.item.id !== '' ){
            handleUpdateItem();
            if( closeDialog ){
                props.setOpenItemDetails(false);
                props.setItem(null);
            }
        } else if( isValidItem && !props.item.id ){
            handleCreateItem();
            if( closeDialog ){
                props.setOpenItemDetails(false);
                props.setItem(null);
            }
        } else {
            setErrorMessageDialog(constants.errors.validationErrorRequiredFields);
        }

        
    }


    const handleUpdateItem = () => {

        props.setIsLoading(true);

        updateItem(props.item, tenantId).then( itemResponse => {

            if( itemResponse !== null ){
                props.setSuccessMessage(constants.errors.editItemSuccess);
                props.callbackItemHandler();
                /*
                getAllActiveItems( tenantId ).then( activeItemsResponse => {
                    if( activeItemsResponse !== null ){
                        setActiveItems(activeItemsResponse);
                    }
                    setIsLoading(false);
                });
                */
                props.setIsLoading(true);
            } else {
                props.setErrorMessage(constants.errors.editItemError);
                props.setIsLoading(false);
            }
            
        });
    }


    const handleCreateItem = () => {

        props.setIsLoading(true);

        createItem(props.item, tenantId).then( itemResponse => {

            if( itemResponse !== null ){
                props.setSuccessMessage(constants.errors.createItemSuccess);
                props.callbackItemHandler();
                /*
                getAllActiveItems( tenantId ).then( activeItemsResponse => {
                    if( activeItemsResponse !== null ){
                        setActiveItems(activeItemsResponse);
                    }
                    setIsLoading(false);
                });
                */
                props.setIsLoading(true);
            } else {
                props.setErrorMessage(constants.errors.createItemError);
                props.setIsLoading(false);
            }
            
        });
    }


    const handleChangeItemCategoryAttribute = (attributeName, attributeValue) => {
        if( props.item ){

            console.debug('[EditItem][handleChangeItemCategoryAttribute] attributeValue: ', attributeValue);

            let itemAux = { ...props.item };
            let categoryListAux = itemAux.category_list;

            if( !categoryListAux || categoryListAux.length == 0){
                categoryListAux.push(attributeValue);
            } else {
                categoryListAux[0] = attributeValue;
            }

            props.setItem(itemAux);
        }
    }

    const handleChangeItemAttribute = (attributeName, attributeValue) => {
        if( props.item ){
            const itemAux = { ...props.item };
            itemAux[attributeName] = attributeValue;
            props.setItem(itemAux);
        }
    }

    const handleChangeItemPrice = (attributeName, attributeValue) => {
        if( props.item ){
            let itemAux = { ...props.item };
            if( !itemAux.price_unit_list || itemAux.price_unit_list.length === 0 ){
                const priceUnitAux = createEmptyPriceUnit(itemAux.user_id);
                itemAux.price_unit_list.push(priceUnitAux);
            }
            itemAux.price_unit_list[0].price = attributeValue;
            props.setItem(itemAux);
        }
    }

    const handleCloseItemDetails = () => {
        props.setItem(null);
        props.setOpenItemDetails(false);
    }

    return (

    <Dialog
        open={props.openItemDetails}
        onClose={handleCloseItemDetails}
        key="itemDetails"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle
            sx={constants.styles.textNormal}
            id="alert-dialog-title"
        >
            <strong>Item: {props.item ? props.item.name : ''}</strong>
        </DialogTitle>
        <DialogContent>
                <Grid container >
                    <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                        <Grid container direction="row" spacing={0}
                            justifyContent={'left'}
                        >
                            <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                                <ErrorMessageChip message={errorMessageDialog} />
                            </Grid>

                            <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                                &nbsp;
                            </Grid>

                            <AutocompleteComponent
                                fieldId={'input-item-category'}
                                fieldName={'Category'} 
                                fieldValue={props.item && props.item.category_list && (props.item.category_list.length > 0) ? props.item.category_list[0] : null}
                                attributeName={'category'}
                                optionList={activeCategories}
                                optionAttributeLabel={'name'}
                                isClient={false}
                                handleChangeField={handleChangeItemCategoryAttribute}

                                sx={constants.styles.textFieldNormalDialog}
                                labelSx={3}
                                labelMd={3}

                                inputSx={8}
                                inputMd={8}
                            />

                            <InputComponent 
                                fieldName={"Name"} 
                                fieldValue={ props.item ? props.item.name : '' } 
                                fieldId={'input-item-name'} 
                                attributeName={'name'} 
                                handleChangeField={handleChangeItemAttribute}
                                required={true} 
                                sx={constants.styles.textFieldNormalDialog}
                                labelSx={3}
                                labelMd={3}

                                inputSx={8}
                                inputMd={8}

                            />
                            <InputComponent 
                                fieldName={"SKU"} 
                                fieldValue={ props.item ? props.item.sku : '' } 
                                fieldId={'input-item-sku'} 
                                attributeName={'sku'} 
                                handleChangeField={handleChangeItemAttribute}
                                required={true} 
                                sx={constants.styles.textFieldNormalDialog}
                                labelSx={3}
                                labelMd={3}

                                inputSx={8}
                                inputMd={8}

                            />

                            <InputComponent 
                                fieldName={"Price"} 
                                fieldValue={ props.item && props.item.price_unit_list && (props.item.price_unit_list.length > 0) ? props.item.price_unit_list[0].price : '' } 
                                fieldId={'input-item-price'} 
                                attributeName={'price'} 
                                handleChangeField={handleChangeItemPrice}
                                required={true} 
                                sx={constants.styles.textFieldNormalDialog}
                                labelSx={3}
                                labelMd={3}

                                inputSx={8}
                                inputMd={8}

                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    inputProps: {
                                        style: { textAlign: "right" },
                                        min: 0,
                                    }
                                }}
                                numberValidation={true}

                            />
                            

                            <InputComponent 
                                fieldName={"Description"} 
                                fieldValue={ props.item ? props.item.description : '' } 
                                fieldId={'input-item-description'} 
                                attributeName={'description'} 
                                handleChangeField={handleChangeItemAttribute}
                                required={true} 
                                sx={constants.styles.textFieldNormalDialog}
                                labelSx={3}
                                labelMd={3}

                                inputSx={8}
                                inputMd={8}

                                multiline={true}
                                rows={4}

                            />
                            
                            { props.item && props.item.id &&
                                (
                                    <Grid container direction="row" spacing={0}
                                        justifyContent={'center'}
                                        alignContent={'center'}
                                    >
                                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                                            <UploaderComponent
                                                object={props.item}
                                                prefix={'item'}
                                                folder={'items'}
                                                imageListAttributeName={'image_list'}
                                                setObject={props.setItem}
                                                saveObject={handleSaveItemNoClose}
                                                
                                            />
                                        </Grid>
                                        <Grid item 
                                            sx={constants.styles.sectionQR} 
                                            xs={12} 
                                            md={12} 
                                        >
                                            <QRCodeSVG 
                                                value={ getPublicItemUrl(props.item.id, tenantId) } 
                                                size={constants.styles.qrCodeDefaultSize}
                                            />
                                        </Grid>
                                    </Grid>
                                )
                            }

                            
                        


                        </Grid>
                    </Grid>
                </Grid>
        </DialogContent>
        <DialogActions>
            <Button 
                variant="outlined" 
                size="small"
                sx={constants.styles.buttonNormal}
                onClick={handleCloseItemDetails}
            >
                Cancel
            </Button>
            <Button 
                variant="outlined" 
                size="small"
                sx={constants.styles.buttonNormal}
                onClick={handleSaveItem} 
                autoFocus
            >
                Save
            </Button>
        </DialogActions>
    </Dialog>

    )

}

export default EditItem;
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React from "react";
import constants from "../util/Constants";
import { security_constants, validateUserPrivileges } from "../../../service/tenant/SecurityService";

function QuoteItemDetails(props){

    return(
        <Dialog
            open={props.openDetailsDialog}
            onClose={props.handleCloseDetailsDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                sx={constants.styles.textNormal}
                id="alert-dialog-title"
            >
                <strong>Item: { props.item ? props.item.name : '' }</strong>
            </DialogTitle>
            <DialogContent>
                <DialogContentText 
                    sx={constants.styles.textNormal}
                    id="alert-dialog-description"
                >
                    { validateUserPrivileges([security_constants.privileges.admin_clients_all_access]) ?
                        <>
                            Unit price: { props.item ? props.item.unit_price : '' } <br/>
                        </>
                        :
                        <></>
                    }
                    Details: <br/>
                    { props.item ? props.item.details : '' }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button 
                    variant="outlined" 
                    size="small"
                    sx={constants.styles.buttonNormal}
                    onClick={props.handleCloseDetailsDialog}
                >
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );

}

export default QuoteItemDetails;
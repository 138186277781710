//Storage Service Delegate
import { S3 } from "aws-sdk";
import AWS from "aws-sdk";

const S3_ACCESS_KEY_ID = "AKIA6D6JBDKW5SPQVIT5";
const S3_SECRET_ACCESS_KEY = "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";

const S3_BUCKET = "jobs-manager-images"; 
const REGION = "us-east-2"; 

const DEFAULT_EXPIRES_SIGNED_URLS = 604800;

AWS.config.update({
    accessKeyId: S3_ACCESS_KEY_ID,
    secretAccessKey: S3_SECRET_ACCESS_KEY,
});

const s3 = new S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
});

export const buildFilePath = (tenantId, objectId, prefix, folder, fileName) => {
    const randomInt = Math.floor(Math.random() * 1000);
    const filePath = folder + '/' + prefix + '_' + tenantId + '_' + objectId + '_' + randomInt + '_' + fileName;
    return filePath;
}

export const getSingleFileName = (prefix, filePath) => {
    var filePathParts = filePath.split(prefix);
    if( filePathParts.length >= 1 ){
        filePathParts = filePathParts[2].split('_');
    }
     
    var singleFileName = '';

    if( filePathParts.length > 1 ){
        filePathParts.forEach( (namePart, index) => {
            if( index > 3 ){
                singleFileName += namePart + '_';
            }
        });

        if( singleFileName.length > 1 ){
            singleFileName = singleFileName.substring(0, singleFileName.length -1 );
        }

        return singleFileName;
    } else {
        return filePath;
    }
}

export const uploadFileToBucket = async (objectId, prefix, folder, file) => {

    const filePath = buildFilePath(objectId, prefix, folder, file.name);

    const uploadResult = await uploadFilePathToBucket(filePath, file);

    return uploadResult;
}

export const uploadFilePathToBucket = async (filePath, file) => {

    const params = {
      Bucket: S3_BUCKET,
      Key: filePath,
      Body: file,
    };

    try {
        const upload = await s3.putObject(params).promise();
        console.debug('[StorageService][uploadFileToBucket] File uploaded successfully');
        console.debug(upload);

        var options = {
            Bucket: S3_BUCKET,
            Key: file.name, 
            Expires: 3000 
        };

        return upload;
    } catch (error) {
        console.error('[StorageService][uploadFileToBucket] An error occurred: ', error.message);
        console.error(error);
        return null;
    }

}

export const getSignedUrlToFile = async(objectId, prefix, folder, fileName) => {

    const filePath = buildFilePath(objectId, prefix, folder, fileName);

    const url = await getSignedUrlToFilePath(filePath);
    
    return url;
}


export const getSignedUrlToFilePath = async(filePath) => {

    try{
        var options = {
            Bucket: S3_BUCKET,
            Key: filePath, 
            Expires: DEFAULT_EXPIRES_SIGNED_URLS 
        };
        const url = s3.getSignedUrl('getObject', options)

        console.debug('[StorageService][getSignedUrlToFile] url: ', url);

        return url;
    } catch (error) {
        console.error('[StorageService][getSignedUrlToFile] An error occurred: ', error.message);
        console.error(error);
        return null;
    }
    
}


export const deleteFileInBucket = async (filePath) => {

    const params = {
      Bucket: S3_BUCKET,
      Key: filePath,
    };

    try {
        
        const deleteResponse = await s3.deleteObject(params).promise();
        console.debug('[StorageService][deleteFileInBucket] File uploaded successfully');
        console.debug(deleteResponse);
        return deleteResponse;

    } catch (error) {
        console.error('[StorageService][deleteFileInBucket] An error occurred: ', error.message);
        console.error(error);
        return null;
    }

}
import React, { useEffect, useRef, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { getTenantLocalStorage } from "../../../service/tenant/TenantService";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PrincipalLayout from "../menu/PrincipalLayout";
import { Delete, EditNote, PictureAsPdf } from "@mui/icons-material";
import ErrorMessageChip from "../util/ErrorMessageChip";
import constants from "../util/Constants";
import { useNavigate } from "react-router";
import SuccessMessageChip from "../util/SuccessMessageChip";
import AutocompleteComponent from "../util/AutocompleteComponent";
import { createItem, getAllActiveItems, getItemById, getPublicItemUrl, logicalDeleteItem, updateItem } from "../../../service/tenant/ItemService";
import InputComponent from "../util/InputComponent";
import SelectComponent from "../util/SelectComponent";
import { createEmptyItem, createEmptyPriceUnit, createEmptyProject, validateItem } from "../../../service/tenant/ValidationService";
import { getCategoriesFirstLevel } from "../../../service/tenant/CategoryService";
import { QRCodeCanvas, QRCodeSVG } from "qrcode.react";
import UploaderComponent from "../util/UploaderComponent";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ItemsPDF from "./ItemsPDF";
import ItemQRCode from "../item/ItemQRCode";
import EditItem from "./EditItem";


function Items(props){

    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
    const [openItemDetails, setOpenItemDetails] = useState(false);
    const [activeItems, setActiveItems] = useState([]);
    const [qrURIs, setQrURIs] = useState([]);
    const [item, setItem] = useState([]);
    
    const tenant = getTenantLocalStorage();
    const tenantId = tenant.id;
    const navigate = useNavigate();


    useEffect( () => {

        setIsLoading(true);
        getAllActiveItems( tenantId ).then( activeItemsResponse => {
            if( activeItemsResponse !== null ){

                var qrURIsAux = [];

                activeItemsResponse.forEach( item => {
                    qrURIsAux.push(' ');
                });

                setQrURIs(qrURIsAux);
                setActiveItems(activeItemsResponse);
            }
            setIsLoading(false);
        });


    }, []);


    const handleEditItem = (itemAux) => {
        console.debug('[Items][handleEditItem] item: ', itemAux);
        setIsLoading(true);

        getItemById( tenantId, itemAux.id ).then( itemResponse => {

            if( itemResponse != null ){
                setItem(itemResponse);
                setOpenItemDetails(true);
                setIsLoading(false);
            }

        });
        
        
    }

    const handleNewItem = () => {
        console.debug('[Items][handleNewItem] ');
        const itemAux = createEmptyItem();
        setItem(itemAux);
        setOpenItemDetails(true);
    }

    const handleDeleteItem = (itemAux) => {
        console.debug('[Items][handleDeleteItem] project: ', itemAux);
        setOpenDeleteAlert(true);
        setItem(itemAux);
    }

    const handleConfirmDeleteItem = () => {
        console.debug('[Items][handleConfirmDeleteItem] ');
        
        const tenantId = tenant.id;

        setSuccessMessage('');
        setErrorMessage('');

        setIsLoading(true);

        logicalDeleteItem(item, tenantId).then( itemDeleteResponse => {

            if( itemDeleteResponse !== null ){

                getAllActiveItems( tenantId ).then( activeItemsResponse => {
                    if( activeItemsResponse !== null ){
                        setActiveItems(activeItemsResponse);
                    }
                    setIsLoading(false);
                });

                setSuccessMessage(constants.errors.createItemSuccess);
            } else {
                setErrorMessage(constants.errors.createItemError);
                setIsLoading(false);
            }

        });

        setOpenDeleteAlert(false);
        
    }

    const handleCloseDeleteDialog = () => {
        setItem(null);
        setOpenDeleteAlert(false);
    }

    const handleCloseItemDetails = () => {
        setItem(null);
        setOpenItemDetails(false);
    }


    const callbackItemHandler = () => {
        setIsLoading(true);
        getAllActiveItems( tenantId ).then( activeItemsResponse => {
            if( activeItemsResponse !== null ){
                setActiveItems(activeItemsResponse);
            }
            setIsLoading(false);
        });
    }


    

    return (
        <PrincipalLayout section={constants.menu.admin_admin_section} isLoading={isLoading} 
                        notificationNumber={props.notificationNumber} 
                        setNotificationNumber={props.setNotificationNumber}
        >

                <Toolbar />

                <Paper sx={constants.styles.paperContainer}>
                    <Grid container direction="row" spacing={0}
                        justifyContent={'left'}
                        alignContent={'center'}
                        alignItems={'center'}
                    >
                        <Grid item sx={constants.styles.sectionTitle} xs={11} md={11}>
                            <Typography 
                                variant="h6"
                                sx={constants.styles.textTitle}
                            >
                                Items &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Typography>
                            
                        </Grid>

                        <Grid item xs={1} md={1}>
                            <Grid container 
                                spacing={0}
                                alignItems={'flex-end'}
                                alignContent={'flex-end'}
                                flexDirection={'column'}
                            >
                                <Grid item xs={12} md={12}>
                                    { activeItems && activeItems.length > 0 &&
                                        (
                                            <PDFDownloadLink
                                                document={
                                                    <ItemsPDF 
                                                        itemList={activeItems} 
                                                        qrURIs={qrURIs}
                                                    />
                                                }
                                                fileName={"Item_Catalog.pdf"}
                                                
                                                >
                                                <IconButton 
                                                    variant="outlined" 
                                                    size="small" 
                                                    sx={constants.styles.buttonNormal}
                                                >
                                                    <PictureAsPdf 
                                                        sx={constants.styles.iconSize}
                                                    />
                                                </IconButton>
                                            </PDFDownloadLink>
                                        )
                                    }

                                </Grid>

                            
                            </Grid>
                        </Grid>
                        
                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                            &nbsp;
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <ErrorMessageChip message={errorMessage} />
                        </Grid>
                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <SuccessMessageChip message={successMessage} setMessage={setSuccessMessage} />
                        </Grid>

                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                            &nbsp;
                        </Grid>

                        <Grid item xs={12} md={12} sx={constants.styles.sectionTable}>
                            <TableContainer component={Paper} variant="outlined">
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={constants.styles.tableHeader} >Name</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} >SKU</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} >Price</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} align="center">QR Code</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} align="center">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {activeItems.map((row, index) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell sx={constants.styles.tableCell}>
                                                {row.name}
                                            </TableCell>
                                            <TableCell sx={constants.styles.tableCell}>
                                                {row.sku}
                                            </TableCell>
                                            <TableCell sx={constants.styles.tableCell} align="right">
                                                ${row.price_unit_list && (row.price_unit_list.length > 0) ? row.price_unit_list[0].price : ''}
                                            </TableCell>
                                            <TableCell sx={constants.styles.tableCell} align="center">
                                                        <ItemQRCode
                                                            key={index}
                                                            item={row}
                                                            index={index}
                                                            qrURIs={qrURIs}
                                                            setQrURIs={setQrURIs}
                                                        />
                                            </TableCell>
                                            <TableCell sx={constants.styles.tableCell} align="center">
                                                <IconButton 
                                                    size="small" 
                                                    title="Edit item"
                                                    onClick={() => handleEditItem(row)}
                                                >
                                                    <EditNote/>
                                                </IconButton>
                                                <IconButton 
                                                    size="small" 
                                                    title="Delete item"
                                                    onClick={() => handleDeleteItem(row)}
                                                >
                                                    <Delete/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid 
                            container
                            alignItems={'flex-end'}
                            alignContent={'flex-end'}
                            flexDirection={'column'}
                        >

                            <Grid item  
                                xs={12} md={12} 
                                sx={constants.styles.sectionButtons}
                            >
                                <Button 
                                    variant="outlined" 
                                    size="small" 
                                    sx={constants.styles.buttonNormal}
                                    onClick={handleNewItem}
                                > 
                                        New Item 
                                </Button>

                            </Grid>

                        </Grid>

                    </Grid>
                    
                </Paper>

                <Dialog
                    open={openDeleteAlert}
                    onClose={handleCloseDeleteDialog}
                    key="deleteItemDialog"
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        sx={constants.styles.textNormal}
                        id="alert-dialog-title"
                    >
                        <strong>Do you confirm to delete this item?</strong>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText 
                            sx={constants.styles.textNormal}
                            id="alert-dialog-description"
                        >
                            Item name: <strong>{ item ? item.name : ''}</strong>
                            <br/>If you delete this item will no longer be available anymore.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant="outlined" 
                            size="small"
                            sx={constants.styles.buttonNormal}
                            onClick={handleCloseDeleteDialog}
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="outlined" 
                            size="small"
                            sx={constants.styles.buttonNormal}
                            onClick={handleConfirmDeleteItem} 
                            autoFocus
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>


                <EditItem
                    item={item}
                    openItemDetails={openItemDetails}

                    setItem={setItem}
                    setOpenItemDetails={setOpenItemDetails}

                    setIsLoading={setIsLoading}
                    setSuccessMessage={setSuccessMessage}
                    setErrorMessage={setErrorMessage}
                    
                    callbackItemHandler={callbackItemHandler}
                    
                />
                
        </PrincipalLayout>
    );
}

export default Items;